
.Flex{
  display:flex;
}

.Flex-column{
  display:flex;
  flex-direction: column;
}

.Row-reverse{
  flex-direction: row-reverse;
}

.Align-center{
  align-items: center;
}

.Justify-center{
  justify-content: center;
}

.Justify-between{
  justify-content: space-between;
}


.Height-100{
  height: 100vh;
}

.Flex-1{
  flex-grow:1;
}


a.Grey-button:hover{
  text-decoration:none !important;
}

h3, h4, p, a{
  font-family: 'Quicksand', sans-serif;
  margin:0;
  color:#00000096;
}

p {
  font-weight:300;
  color:#00000096;
  font-size:18px;
  line-height:1.3;

}

h1, h2{
  font-family: 'Work Sans', sans-serif;
  margin:0;
}

h4{
  /* font-weight:300; */
  font-size: 22px;
  margin:0;
  color:#00000096;
}

h3{
  font-size: 26px;
}

#map {
  height: 100%;
}

.Text-center{
  text-align: center;
}

.mb-20{
  margin-bottom:20px;
}

.mb-40{
  margin-bottom:40px;
}

.mb-60{
  margin-bottom:60px;
}

.mt-60{
  margin-top: 60px;
}

.mt-30{
  margin-top:30px;
}

.mt-20{
  margin-top:20px;
}

.mt-10{
  margin-top:10px;
}

.Dark-background{
  background-color:rgb(73 73 73);
}

.Bold{
  font-weight:bold;
}

.no-underline{
  text-decoration:none !important;
}

@media only screen and (max-width: 640px){
  .Desktop-mobile-flex{
    display:flex;
    flex-direction: column;
  }
}

@media only screen and (min-width: 640px){
  .Desktop-mobile-flex{
    display:flex;
  }
}

.Gradient-background{
  background: rgb(252,218,46);
  background: linear-gradient(0deg, rgba(252,218,46,0.5466780462184874) 0%, rgba(255,139,69,0.65172006302521) 100%, rgba(253,155,45,0.48785451680672265) 100%);
  max-width: 100vw;
  overflow-x: hidden;
}

li{
  list-style: '🌸';
  margin-right: 3px;
}
li > p{
 margin-left: 10px;
}

ul{
  padding-inline-start: 20px;
}

.Dark-grey-text{
  color: #00000096;
}

.iconify{
  width:30px;
  height:30px;
}

.animate-on-scroll {
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.5s ease;
}

.animate-on-scroll.animate {
  opacity: 1;
  transform: translateY(0);
}

.white-text{
  color:white !important;
}

fieldset{
  border:0px;
  padding: 0px;
}

input::placeholder, textarea::placeholder{
  color: #00000068;
}
