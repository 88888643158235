.Footer > a, .Flex-column > a{
  color:grey;
  padding:2px;
}

.Flex > a{
  color:grey;
  text-decoration: none;
  font-size: 20px;
}
.Footer > a:hover, .Flex-column > a:hover, .Flex > a:hover{
  opacity: 0.8;
}


.mt-40{
  margin-top: 40px;
}

a.Love-link{
  font-size: 12px;
  text-decoration:underline;
  padding-left: 4px;
  color:grey;
}
.Love-text{
  font-size: 12px;
  color:grey;
}

.ml-10{
  margin-left:10px;
}

@media only screen and (max-width: 640px){
  .Footer{
    display:flex;
    flex-direction: column;
    width: 100%;
    height: 65vh;
    justify-content: flex-end;
    padding-left: 20px;
    padding-bottom: 40px;
  }
  .Love-container{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media only screen and (min-width: 640px){
  .Footer{
    display:flex;
    width: 100%;
    height: 30vh;
    justify-content: space-between;
  }

  .ml-40{
    margin-left:40px;
  }

  .mr-40{
    margin-right:40px;
  }
}
