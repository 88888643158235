@media only screen and (max-width: 640px){
  .Navbar{
    height: 100vh;
    width:100vw;
    overflow-y:none !important;
    /* background: rgb(252,218,46);
    background: linear-gradient(0deg, rgba(252,218,46,0.6909357492997199) 0%, rgba(255,139,69,0.65172006302521) 100%, rgba(253,155,45,1) 100%); */
  }
  .Logo{
    color: white;
    padding-left: 15px;
    font-size: 20px;
    font-weight:bold;
    text-decoration:none;
  }
  .Nav-links > a{
    color:white;
    text-decoration: none;
    font-size:30px;
  }
  .Nav-links{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height:55%;
  }

  .Navbar-header{
    display: flex;
    width:100%;
    justify-content: space-between;
    align-items:center;
    background-color: rgba(73, 73, 73, 0.124);
    height:10vh;
  }
  .Navbar-wrapper{
    height:calc(100vh - 67px);
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    background: transparent;
    margin-top: 60px;
  }

  .X-btn, .Hamburger-button{
    background-color: transparent;
    border: none;
    padding: 10px 10px 0px 0px;
  }

  .X{
    height:35px;
    width:35px;
    color:white;
  }

  .Navbar-desktop{
    display:none;
  }
  .Transparent-background{
    background:transparent;
  }

  .active{
    border-bottom: solid 2px white;
  }
  .Small-btn{
    text-decoration: none;
    padding: 15px 20px !important;
    color:#00000073 !important;
    border-color: #00000073 !important;
    box-shadow: 0 0.2rem 0 #00000073 !important;
    font-size:20px;
    border-radius: 30px !important;
  }
  .no-scroll{
    overflow: hidden;
  }
}

@media only screen and (min-width: 640px){
  .Logo{
    color: white;
    text-decoration: none;
    font-size:22px;
    font-weight:bold;
  }

  .Navbar{
    width:100vw;
    background:rgb(89, 89, 89);
  }
  .Nav-links > a{
    color:white;
    text-decoration: none;
    margin:8px;
    font-weight: 500;
    font-size: 16px;
  }
  .Nav-links > a:hover{
    border-bottom: solid 1px rgba(255, 255, 255, 0.325);
  }
  .Nav-links{
    display:flex;
    align-items: center;
    justify-content: space-between;
  }
  .Navbar-wrapper{
    display:flex;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    padding: 0px 20px;
    background-color:rgba(73, 73, 73, 0.124);
  }

  .Navbar-header{
    display: flex;
    justify-content: space-between;
  }

  .X-btn, .Hamburger-button, .Navbar-mobile{
    display:none;
  }

  .active{
    border-bottom: solid 1px white;
  }

  .Small-btn{
    padding: 12px 20px !important;
  }
}
