/* Home page */
@media only screen and (max-width: 640px){
  .Hero{
    background-image: url("../images/zoe-hero.jpeg");
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: 100vh;
    width:100vw;
    background-position:center;
  }
  .Hero > h1{
    margin: 0px;
    color: white;
    font-size: 44px;
    font-weight: 400;
    padding: 60px 10px 20px 20px;
    letter-spacing: 1.2px;
  }
  .Hero > a{
    color:white;
    font-size: 20px;
    font-weight: 600;
    padding:30px 0px 0px 20px;
    letter-spacing: 1px;
  }
  .Group-classes-image-container{
    height: 35vh;
    align-self: center;
    width: auto;
    border-radius: 50%;
    border: solid 0.75px white;
    align-self: center;
    overflow:hidden;
  }
  .Group-classes-image-container > img{
    height: 35vh;
    width: auto;
  }
  .Group-classes-container{
    display:flex;
    flex-direction:column;
    justify-content: center;
    height: 70vh;
    align-items:center;
  }
  .Group-classes-container > .Grey-button{
    margin-left:20px;
    margin-top:20px;
    width:100px;
  }
  .View-more-btn{
    margin-top:20px;
    color:white;
    font-size: 22px;
    display: flex;
    align-items: center;
  }
  .Newsletter-container{
    height:90vh !important;
    width:100vw;
    background-image: url('../images/zoe-about.jpeg');
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    justify-content: center;
    align-items:center;
  }
  .Zoe-portrait{
    width: 90%;
    margin: 20px;
    height: 75vh;
    border-radius: 50%;
    border: solid 0.75px #00000096;
    object-fit:cover;
  }
  .About-me-text{
    justify-content: center;
    padding:0px 22px ;
  }
  .Class-offerings{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .Offerings-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Contact-container{
    padding:20px
  }
  .Classes-section{
    align-items:center;
  }
  .Classes-text{
    padding: 20px;
    justify-content: center;
  }
  .Classes-image{
    width: 90%;
    margin: 30px;
    height:60%;
    border-radius: 10px;
    border: solid 0.75px black;
  }
  .About-me-title{
    font-size: 40px;
    font-weight: 400;
  }
  .Contact-form{
    width:100%;
  }
  .Group-classes-container > h1{
    padding: 20px;
    color:white;
    font-weight: normal;
    text-align: right;
    font-size:26px;
  }
  .location-section{
    display:flex;
    flex-direction: column;
  }
  .Location-info{
    text-align:right;
    margin-right:20px;
    height:55vh;
    justify-content: center;
  }
  input[type="submit"]{
    height: 44px !important;
    width: 110px !important;
    font-family: 'Quicksand', sans-serif;
  }
}
@media only screen and (min-width: 640px){
  .Hero{
    background-image: url("../images/zoe-hero.jpeg");
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
  }
  .Hero{
    height: calc(100vh - 90px);
    width: 100vw;
  }
  .Hero > h1{
    margin:0px;
    color:white;
    font-size:60px;
    font-weight: 400;
    width:60%;
    padding:60px 0px 20px 30px;
    letter-spacing: 1.2px;
  }
  .Hero > a{
    color:white;
    font-size: 20px;
    font-weight: 600;
    padding:20px 0px 0px 30px;
    letter-spacing: 1px;
  }

  .Book-a-class-link{
    transition: transform .3s ease-out;
    display: inline-block;
  }

  .Book-a-class-link:hover{
    transform: translateX(5px);
  }

  .Group-classes-image-container{
    width: 65vw;
    height: 70vh;
    border-radius: 50%;
    border: solid 0.75px white;
    overflow: hidden;
    align-self: center;
  }

  .Group-classes-container > img, .Group-classes-image{
    width: 65vw;
    height: 70vh;
    position: relative;
    object-fit: cover;
    transition: all 1s ease;
  }

  .Group-classes-image:hover{
    transform: scale(1.05)
  }

  .View-more-btn{
    position: absolute;
    right: 300px;
    top: 300px;
    font-size:26px;
    color:white;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .Button:hover{
    cursor: pointer;
    transform: translate(0, 0.3rem);
    box-shadow: 0 0.025rem 0 white;
  }

  .Zoe-portrait{
    width: 40%;
    margin: 60px;
    height: 90vh;
    border-radius: 50%;
    border: solid 0.75px #00000096;
  }
  .About-me-text{
    justify-content: center;
    padding-right:60px;
  }
  .Class-offerings{
    display: flex;
    justify-content: space-between;
    width:70%;
  }
  .Offerings-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height:80vh;
    padding: 0px 30px;
  }
  .Classes-section{
    height: 60vh;
    align-items: center;
  }
  .Classes-text{
    padding-left:50px;
    justify-content: center;
    margin-top:-40px;
  }
  .Classes-image{
    width: 50%;
    margin: 30px;
    height:60%;
    border-radius:10px;
    border: solid 0.75px black;
  }
  .Group-classes-container{
    height:100vh;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .About-me-title{
    font-size: 50px;
  }
  .Contact-form{
    width:50%;
  }
  .Contact-container{
    height:85vh;
  }
  .Group-classes-container > h1{
    padding: 40px 0px;
    color:white;
    text-align: center;
    font-size: 32px;
  }
  .location-section{
    display:flex;
    justify-content: flex-end;
    height:60vh;
  }

  .Location-info{
    text-align:right;
    margin-right:10px;
    margin-left:5px;
  }
  .Newsletter-container{
    height:70vh;
    width:100vw;
    background-image: url('../images/zoe-about.jpeg');
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    justify-content: center;
    align-items:center;
    background-position:center;
  }

}
.Group-classes-container{
  width:100vw;
}

.Newsletter-container > h1, .Newsletter-container > h3{
  color:white;
}

/* About */
.About-me-title{
  font-weight: 400;
}

.About-me-container{
  background-color:rgba(73, 73, 73, 0.124);
  display: flex;
  align-items: flex-end;
  color: white;
  padding: 30px;
  height:8vh;
}


.Light-background{
  background: #f5f5f554;
}

/* Philosophy */
.Philosophy-text > h3, .Philosophy-text > p{
  padding-bottom:50px;
}

/* Classes */

.Book-a-class-section{
  height:45vh;
  width:100vw;
  background: #e4e4e44d;
  justify-content: space-evenly;
  align-items:center;
}
.Book-a-class-section > a{
  text-decoration: none;
  padding: 15px 20px !important;
  color:#00000096;
  border-color: #00000096;
  box-shadow: 0 0.2rem 0 #00000096;
}
.Book-a-class-section > h1{
  font-size: 45px;
  color:#00000096;
}

/* Offerings */
.Class-offering{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:40px;

}
.Class-offering-image{
  border-radius: 10px;
  height: 220px;
  width: 270px;
  border: solid 0.75px black;
  transition: border-radius 0.5s;
  object-fit: cover;
}

a > .Class-offering-image:hover{
  border-radius: 50%;
}

/* Contact */

label{
  font-family: 'Rubik', sans-serif;
  margin-top:20px;
  margin-bottom:5px;
  color:#00000096;
}

.Sm-label{
  font-size:12px;
  margin-top:5px;
}

input{
  height: 30px;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  border: solid 0.75px #00000096;
  border-radius: 10px;
  padding: 5px 10px;
  background-color: rgb(237 237 237 / 15%);
}

textarea{
  height: 100px;
  font-family: 'Rubik', sans-serif;
  font-size:16px;
  border:solid 0.75px #00000096;
  border-radius: 10px;
  padding:10px;
  background-color: rgb(237 237 237 / 15%)
}

input[type="submit"]{
  height: fit-content;
  color:#00000096;
  border-color:#00000096;
  box-shadow: 0 0.2rem 0 #00000096;
}

input[id="lname"], input[id="fname"]{
  width: 90%;
}

input:focus, input:focus-visible, textarea:focus, textarea:focus-visible{
  outline: orange solid 1px;
  border-color:  transparent;
  border-radius:10px;
}

.ml-10{
  margin-left:10px;
}

.Button{
  padding: 15px 25px;
  color: white;
  text-decoration: none;
  font-weight: bold;
  box-shadow: 0 0.2rem 0 white;
  border: 0.1rem solid white;
  border-radius: 1.5rem;
  transition: 0.15s;
  transition-timing-function: ease-out;
  width: fit-content !important;
  text-align: center;
}

#map{
  height:400px;
  width:100%;
}
